import React from "react";

const TagIcon = props => {
    const { fill } = props;

    return (
        <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" fill={fill}>
            <g id="Layer_21" data-name="Layer 21">
                <path d="m18.21 2h-11.42a2.83 2.83 0 0 0 -2.79 2.86v16.34a1.67 1.67 0 0 0 1 1.54 1.57 1.57 0 0 0 1.74-.27l5.35-4.74a.69.69 0 0 1 .86 0l5.35 4.74a1.59 1.59 0 0 0 1.74.27 1.67 1.67 0 0 0 1-1.54v-16.34a2.83 2.83 0 0 0 -2.83-2.86z"></path>
            </g>
        </svg>
    );
}

export default TagIcon;
