import React, {useEffect, useState} from "react"
import $ from 'jquery'
import StringTools from "../../../../class/tool/StringTools"
import TrashIcon from "../../../icons/TrashIcon"

const InputLibrary = props => {
    const { attribute, returnType, classname, type, code, value, placeholder, handleChange } = props
    const [ box, setBox ] = useState(false)
    const [ select, setSelect ] = useState(false)
    const [ catalogIcons, setCatalogIcons ] = useState([])
    const [ paymentIcons, setPaymentIcons ] = useState([])
    const [ tagIcons, setTagIcons ] = useState([])
    const [ widthBox, setWidthBox ] = useState(0)
    const functionIcons = {
        1: ["1_g", "1_b", "1_r", "1_o", "1_v"],
        2: ["2_fg", "2_fb", "2_ho", "2_fo", "2_hg", "2_hb"],
        4: ["4_g", "4_b", "4_v", "4_o", "4_o", "4_c"],
        7: ["7_g", "7_b", "7_v", "7_o"],
        8: ["8_g", "8_o", "8_c", "8_v"],
        201: ["201_g", "201_b", "201_r", "201_v", "201_o"],
        202: ["202_g", "202_b", "202_v", "202_r", "202_o"],
        203: ["203_b", "203_o", "203_g", "203_v"],
        204: ["204_r", "204_b", "204_g", "204_v"],
        205: ["205_g", "205_b", "205_o", "205_v", "205_r"],
        206: ["206_g", "206_b", "206_o", "206_v", "206_r"],
        207: ["207_r", "207_o", "207_bd", "207_go", "207_bc"],
        301: ["301_g", "301_b", "301_o", "301_v", "301_r"],
        304: ["304_g", "304_b", "304_o", "304_v", "304_r"],
        305: ["305_b", "305_o", "305_g", "305_db"],
        307: ["307_c", "307_bc", "307_bd", "307_g"],
        308: ["308_g", "308_b", "308_o", "308_v", "308_r", "308_tmb", "308_tmo"],
        310: ["310_g", "310_o", "310_v", "310_r"],
        401: ["401_g", "401_b", "401_o", "401_o", "401_v"],
        402: ["402_g", "402_b", "402_v"],
        801: ["801_g", "801_b", "801_o", "801_v"],
        950: ["950_g", "950_v"],
        951: ["951_g", "951_r"]
    }

    const initIcons = () => {
        let maxCatalog = 41
        let maxPayment = 18
        let maxTag = 7

        let tmpCatalog = []
        let tmpPayment = []
        let tmpTag = []

        for (let i = 1; i <= maxCatalog; i++)
            tmpCatalog.push("catalog_" + i)

        for (let i = 1; i <= maxPayment; i++)
            tmpPayment.push("payment_" + i)

        for (let i = 1; i <= maxTag; i++)
            tmpTag.push("tag_" + i)

        setCatalogIcons(tmpCatalog)
        setPaymentIcons(tmpPayment)
        setTagIcons(tmpTag)
    }
    const showBoxIcon = () => {
        setWidthBox($('.inputLibrary .textIcon').innerWidth() - 10)
        setBox(true)
        setSelect(true)
    }
    const closeBox = () => {
        setBox(false)
        setSelect(false)
    }
    const listLibrary = () => {
        if (type === null) return

        switch (type) {
            case "catalog":
                return <div className="library" style={{width: widthBox + 'px'}}>
                    {
                        catalogIcons.map(
                            (icon, index) => (
                                <div key={index} className="itemLib" onClick={() => { handleSelect(icon) }}>
                                    <img src={getResource(icon)} alt={icon}/>
                                </div>
                            )
                        )
                    }
                </div>
            case "payment":
                return <div className="library" style={{width: widthBox + 'px'}}>
                    {
                        paymentIcons.map(
                            (icon, index) => (
                                <div key={index} className="itemLib" onClick={() => { handleSelect(icon) }}>
                                    <img src={getResource(icon)} alt={icon}/>
                                </div>
                            )
                        )
                    }
                </div>
            case "function":
                if (code !== null && functionIcons[code] !== undefined) {
                    return <div className="library" style={{width: widthBox + 'px'}}>
                        {
                            functionIcons[code].map(
                                (icon, index) => (
                                    <div key={index} className="itemLib" onClick={() => { handleSelect(icon) }}>
                                        <img src={getResource(icon)} alt={icon}/>
                                    </div>
                                )
                            )
                        }
                    </div>
                }
                else {
                    return <div className="library" style={{width: widthBox + 'px'}}>
                        <p className={"emptyText"}>Aucune icône disponible</p>
                    </div>
                }
            case "tag":
                return <div className="library" style={{width: widthBox + 'px'}}>
                    {
                        tagIcons.map(
                            (icon, index) => (
                                <div key={index} className="itemLib" onClick={() => { handleSelect(icon) }}>
                                    <img src={getResource(icon)} alt={icon}/>
                                </div>
                            )
                        )
                    }
                </div>
            default: break
        }
    }
    const getResource = icon => {
        let imgSrc = icon;

        if (icon.includes(".svg"))
            imgSrc = imgSrc.replace(".svg", "");

        try {
            return require("../../../../image/ressource/library/" + imgSrc + ".svg");
        }
        catch {
            return "";
        }
    }
    const handleSelect = icon => {
        if (handleChange === undefined || handleChange === null) return
        handleChange(attribute, returnType, icon)
        closeBox()
    }
    const handleRemove = () => {
        handleChange(attribute, returnType, "")
    }

    useEffect(() => {
        initIcons()
    }, [])

    return (
        <div className={"inputLibrary " + (classname !== undefined ? classname : "")}>
            <div className={"textIcon" + (select ? " select" : "") + (value === "" || value === null ? " placeholder" : "")}>
                {
                    (value !== null && value !== "")
                    && <img className={"icon"} src={getResource(value)} alt={value} onClick={showBoxIcon} />
                }
                <span onClick={showBoxIcon}>
                    {
                        (value !== "" && value !== null)
                            ? StringTools.capitalizeFirstLetter(value.replaceAll("_", " "))
                            : placeholder
                    }
                </span>
                <TrashIcon classname="trash" handleClick={ handleRemove } />
            </div>
            {
                box
                && <div className="popover">
                    <div className="cover" onClick={ closeBox }/>
                    { listLibrary() }
                </div>
            }
        </div>
    )
}

export default InputLibrary
