import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import NewObject from "../../../overbox/sheet/client/NewObject";
import ObjectSheet from "../../../sheet/ObjectSheet";
import Listing from "../../../table/Listing";
import usePrevious from "../../../../class/tool/usePrevious";
import ClientController from "../../../../stories/_client/Clients/ClientController";
import TagController from "../../../../stories/_tag/TagController";
import FormBuilder from "../../../../class/tool/FormBuilder";
import ListingContext from "../../../../context/ListingContext";
import "../../../../css/page/content/client/Client.css";

const Clients = props => {
    const { page } = props;
    const item = "clients";
    const itemClass = "client";
    const titleWindow = "Clients";
    const placeholderSearch = "un client";
    const titleNbItems = "clients";
    const emptyList = "Aucun client";
    const textRemoveButton = "ce client";
    const activeHistory = false;
    const queryParams = new URLSearchParams(window.location.search);
    const [ pageSelect, setPageSelect ] = useState(page != null ? page : 1);
    const [ loading, setLoading ] = useState(true);
    const [ list, setList ] = useState([]);
    const [ filterValues, setFilterValues ] = useState({});
    const [ pagination, setPagination ] = useState(null);
    const [ perPage, setPerPage ] = useState(25);
    const [ sortingName, setSortingName ] = useState("updated_at");
    const [ sortingValue, setSortingValue ] = useState("desc");
    const [ input, setInput ] = useState(queryParams.get("input") !== null ? queryParams.get("input") : "");
    const [ tags, setTags ] = useState([]);
    const [ selectedTags, setSelectedTags ] = useState([]);
    const [ filterRows, setFilterRows ] = useState([]);
    const prevPerPage = usePrevious(perPage);
    const prevInput = usePrevious(input);
    const prevSelectedTags = usePrevious(selectedTags);

    const initFilters = () => {
        let filtersTmp = []

        filtersTmp.push({
            attribute: "tags",
            inputType: "selectTag",
            returnType: "array",
            type: "clients",
            list: [],
            classnameInput: "marginTop",
            refreshList: getTags
        })
        filtersTmp.push({
            attribute: "input",
            inputType: "text",
            returnType: "string",
            classnameInput: "flex border text marginTop marginLeft",
            placeholder: "Rechercher " + placeholderSearch,
            tags: tags
        })

        setFilterValues(prev => ({
            ...prev,
            tags: tags,
            input: input
        }))
        setFilterRows(filtersTmp)
    }
    const getClients = (pTextInput = "", pPage = 1, pPerPage = 25, pSortingName = "", pSortingValue = "", pSelectedTags = []) => {
        let controller;
        let paramInput = pTextInput !== "" ? pTextInput : input;
        let paramPage = pPage !== 1 ? pPage : pageSelect;
        let paramPerPage = pPerPage !== 25 ? pPerPage : perPage;
        let paramSortingName = pSortingName !== "" ? pSortingName : sortingName;
        let paramSortingValue = pSortingValue !== "" ? pSortingValue : sortingValue;
        let paramTags = pSelectedTags.length > 0 ? pSelectedTags : selectedTags;

        setLoading(true);

        controller = new ClientController();
        controller._callback = handleGetClients;
        controller.index(paramInput, paramPage, paramPerPage, paramSortingName, paramSortingValue, false, paramTags);
    }
    const handleGetClients = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setList(list)
                setPagination(pagination !== undefined ? pagination : null )
                break
            default:
                console.log(error)
                break
        }

        setLoading(false)
    }
    const getTags = () => {
        const controller = new TagController();
        controller._callback = handleGetTags;
        controller.index("clients");
    }
    const handleGetTags = (list, error, status) => {
        switch (status) {
            case 200:
                setTags(list);
                break;
            default: break;
        }
    }
    const updateFilters = () => {
        if (filterRows.length === 0) return
        let filtersTmp = filterRows.slice()

        filtersTmp[filtersTmp.findIndex(_ => _.attribute === "tags")].list = tags

        setFilterRows(filtersTmp)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        const obj = FormBuilder.handleChange(filterRows, setFilterValues, attribute, returnType, val, strict)

        switch (attribute) {
            case "input":
                setInput(obj.value)
                break
            case "tags":
                setSelectedTags(obj.value)
                let filtersTmp = filterRows.slice();
                filtersTmp[filtersTmp.findIndex(_ => _.attribute === "input")].tags = obj.value;
                setFilterRows(filtersTmp);
                break
            default: break
        }
    }
    const updatePageSelect = page => {
        setPageSelect(page);
    }
    const handleRefresh = (force = true) => {
        if (!force) {
            if (perPage !== prevPerPage || input !== prevInput || compareArrays(selectedTags, prevSelectedTags)) {
                if (pageSelect !== 1) {
                    setPageSelect(1)
                    return
                }
            }
        }

        getClients(input, pageSelect, perPage, sortingName, sortingValue, selectedTags);
    }
    const handleUpdate = object => {
        let index = list.findIndex(item => item.id === object.id);
        if (index < 0) return;

        let listTmp = list.slice();
        let keys = Object.keys(listTmp[index]);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (object[key] !== undefined)
                listTmp[index][key] = object[key];
        }

        setList(listTmp)
    }
    const handleRemove = () => {
        handleRefresh()
    }
    const compareArrays = (a, b) => {
        return a.toString() === b.toString();
    };

    const model = [
        {
            "key": 1,
            "class": "name",
            "sortingParam": "name",
            "title": "Nom",
            "attributes": ["fullname"],
            "type": "text"
        }, {
            "key": 2,
            "class": "reference",
            "sortingParam": "reference",
            "title": "Référence",
            "attributes": ["reference"],
            "type": "text"
        }, {
            "key": 3,
            "class": "group",
            "sortingParam": "group",
            "title": "Groupe",
            "attributes": ["groupName"],
            "type": "text"
        }, {
            "key": 4,
            "class": "updated_at",
            "sortingParam": "",
            "title": "",
            "attributes": ["updated_at", "last_histo_author"],
            "type": "fromNow"
        }
    ]
    const options = [
        {
            "class": "add",
            "title": "Créer " + placeholderSearch,
            "action": "",
            "link": "/" + item + "/new"
        },
        {
            "class": "export",
            "title": "Exporter",
            "action": "",
            "link": ""
        }
    ]
    const secondaryOptions = []

    useEffect(() => {
        document.title = "Back office - " + titleWindow

        getTags();
        initFilters();
    }, [])
    useEffect(() => {
        updateFilters();
    }, [tags])
    useEffect(() => {
        handleRefresh(false)
    }, [perPage, pageSelect, sortingName, sortingValue, selectedTags])
    useEffect(() => {
        if (prevInput === undefined) return

        const timeoutInput = setTimeout(() => {
            handleRefresh(false)
        }, 1000)

        return () => clearTimeout(timeoutInput)
    }, [input])

    return(
        <ListingContext.Provider value={{page: pageSelect}}>
            <Listing
                item={ item }
                itemClass={ itemClass }
                placeholderSearch={ placeholderSearch }
                titleNbItems={ titleNbItems }
                emptyList={ emptyList }
                model={ model }
                options={ options }
                secondaryOptions={ secondaryOptions }
                filters={ filterRows }
                filterValues={ filterValues }
                changeFilters={ handleChange }
                activeHistory={ activeHistory }
                page={ page }
                pageSelect={ pageSelect }
                updatePageSelect={ updatePageSelect }
                list={ list }
                loading={ loading }
                pagination={ pagination }
                checkable={ false }
                openable={ true }
                setPerPage={ setPerPage }
                sortingName={ sortingName }
                sortingValue={ sortingValue }
                setSortingName={ setSortingName }
                setSortingValue={ setSortingValue }
            />
            <Switch>
                <Route exact path={"/" + item + "/new"}>
                    <NewObject handleIndex={ handleRefresh } />
                </Route>
                <Route exact path={"/" + item + "/:id/(information|barcodes)"}>
                    <ObjectSheet
                        objectType={ itemClass }
                        previousLink={ item }
                        textRemoveButton={ textRemoveButton }
                        handleUpdate={handleUpdate}
                        handleRemove={handleRemove}
                    />
                </Route>
            </Switch>
        </ListingContext.Provider>
    );
}

export default Clients
