import React from "react";
import DatePicker from "react-date-picker";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const InputDate = props => {
    const { attribute, returnType, classname, value, handleChange } = props;

    const changeEvent = value => {
        handleChange(attribute, returnType, value)
    }

    return (
        <div className={ "inputDate" + (classname !== undefined ? " " + classname : "") }>
            <DatePicker onChange={changeEvent} value={value} />
        </div>
    )
}

export default InputDate;
