import React from "react";

const Client2Icon = props => {
    const { fill } = props

    return (<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill={fill}>
            <path d="m76.8005753 2.5h-63.882248c-2.9133434 0-5.2777781 2.3644342-5.2777781 5.2777777v84.4444427c0 2.9133453 2.3644347 5.2777786 5.2777781 5.2777786h63.8822479c2.9133377 0 5.277771-2.3644333 5.277771-5.2777786v-84.4444427c.0000001-2.9133435-2.3644332-5.2777777-5.2777709-5.2777777zm-31.941124 22.1455345c6.9561386 0 12.5927696 5.6366959 12.5927696 12.5927696 0 6.9561386-5.636631 12.5927696-12.5927696 12.5927696-6.9560776 0-12.5927734-5.636631-12.5927734-12.5927696 0-6.9560737 5.6366958-12.5927696 12.5927734-12.5927696zm25.5127563 52.1339073c0 1.8472214-1.4988708 3.3460922-3.3460922 3.3460922h-44.333332c-1.8472233 0-3.3460941-1.4988708-3.3460941-3.3460922v-2.9133453c0-10.1755714 8.2544518-18.4300232 18.4300251-18.4300232h14.165535c10.1755714 0 18.4299583 8.2544518 18.4299583 18.4300232v2.9133453z"></path>
            <path d="m92.3594513 21.0144215v4.5705738c0 3.4411201-2.6705246 6.2594357-6.058876 6.470562v-17.5116969c3.3883514.2216902 6.058876 3.0400057 6.058876 6.4705611z"></path>
            <path d="m92.3594513 38.5683136v4.5705757c0 3.4411201-2.6705246 6.2594376-6.058876 6.470562v-17.5116959c3.3883514.2216873 6.058876 3.0400048 6.058876 6.4705582z"></path>
            <path d="m92.3594513 56.1222076v4.5705757c0 3.4411201-2.6705246 6.2594337-6.058876 6.470562v-17.5116958c3.3883514.2216873 6.058876 3.0400047 6.058876 6.4705581z"></path>
        </svg>);
}

export default Client2Icon;
