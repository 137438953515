import React from "react"
import PhoneInput from "react-phone-input-2"
import fr from 'react-phone-input-2/lang/fr.json'
import "react-phone-input-2/lib/style.css"

const InputPhone = props => {
    const { attribute, returnType, subType, classname, value, handleChange } = props

    const changeEvent = value => {
        handleChange(attribute, returnType, value)
    }

    return (
        <div className={ "inputPhone" + (classname !== undefined ? " " + classname : "") }>
            <PhoneInput
                localization={fr} country={'fr'} onlyCountries={['fr','be','ch','lu','es','it','de','gb','ie','pt','at']} value={value} onChange={changeEvent}
                containerClass={"containerClass"}
                inputClass={"inputClass"}
                buttonClass={"buttonClass"}
                dropdownClass={"dropdownClass"}
                searchClass={"searchClass"}
            />
        </div>
    )
}

export default InputPhone
