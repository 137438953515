import Rest from "../../../class/tool/Rest";
import Client from "./Client";
import FormBuilder from "../../../class/tool/FormBuilder";
import Seller from "../../_setting/Sellers/Seller";

class ClientController {
    #_rest = new Rest();
    _callback = null;
    _baseURL = "clients";
    _baseURLBarcode = "clients/{idClient}/barcodes";

    setFormValues(object, setValues, newObj = true) {
        if (newObj) {
            setValues(prev => ({
                ...prev,
                gender: object.gender !== null ? object.gender : "I",
                firstname: object.firstname,
                lastname: object.lastname,
                mobile: "+33",
                phone: "+33",
                email: object.email,
                address: object.address,
                additional: object.additional,
                postalCode: object.postalCode,
                city: object.city,
                country: "France",
                birthday: object.birthday
            }))
        }
        else {
            let barcodes = []

            if (object.barcodes !== null) {
                for (let key in object.barcodes) {
                    barcodes.push({
                        toAdd: false,
                        toUpdate: false,
                        toDelete: false,
                        id: object.barcodes[key].id,
                        barcode: object.barcodes[key].barcode,
                        conditioning: object.barcodes[key].conditioning,
                        created_at: object.barcodes[key].created_at,
                        updated_at: object.barcodes[key].updated_at,
                        deleted_at: object.barcodes[key].deleted_at
                    })
                }
            }

            setValues(prev => ({
                ...prev,
                gender: object.gender !== null ? object.gender : "I",
                firstname: object.firstname,
                lastname: object.lastname,
                fullname: object.fullname,
                reference: object.reference,
                mobile: object.mobile,
                phone: object.phone,
                email: object.email,
                birthday: object.birthday,
                barcode: barcodes
            }))
        }
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        let obj = Object.assign({}, object)

        return FormBuilder.returnUpdates(obj, datas, compare)
    }
    checkValues(object) {
        const params = []
        let errors = {}
        let method

        for (let i in params) {
            method = ClientController[params[i].method]

            try { method.apply(null, params[i].val) }
            catch (e) { errors.params[i].name = e.message }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }

    index(input = "", page = 1, nb = 25, sortingName = "", sortingValue = "", tree = false, tags = []) {
        let params = ""
        if (page > 0) params += (params.length === 0 ? "?" : "&") + "page=" + page + "&nb=" + nb
        if (input.length > 0) params += (params.length === 0 ? "?" : "&") + "input=" + input
        if (tree) params += (params.length === 0 ? "?" : "&") + "tree="
        if (sortingName.length > 0) params += (params.length === 0 ? "?" : "&") + "sorton=" + sortingName + "&sortvalue=" + sortingValue
        if (tags.length > 0) {
            for (let i in tags)
                params += (params.length === 0 ? "?" : "&") + "tags[]=" + tags[i].id;
        }

        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + params,
            {},
            this.handleIndex);
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = [];
        let pagination = null;

        if(status === 200) {
            let data;

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    }
                }

                data = response.data.data;
            }
            else if (response.data)
                data = response.data;

            for(let item in data)
                listObjects.push(new Seller(data[item]));
        }

        this._callback(listObjects, error, pagination, status);
    }

    show(id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error, status = 200) => {
        const object = !error ? new Seller(response.data) : null;
        this._callback(object, error, status);
    }

    post(datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            datas,
            this.handlePost);
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status);
    }

    put(object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + object.id,
            datas,
            this.handlePut);
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status);
    }

    delete(object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status);
    }

    histo(idCatalog) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/histos',
            {},
            this.handleHisto);
    }
    handleHisto = (response, error, status = 200) => {
        let listObjects = [];

        if(status === 200) {
            let data = response.data;

            for(var item in data)
                listObjects.push(data[item]);
        }

        this._callback(listObjects, error, status);
    }

    histoTarget(idCatalog, id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + id + '/histos',
            {},
            this.handleHistoTarget);
    }
    handleHistoTarget = (response, error, status = 200) => {
        let listObjects = [];

        if(status === 200) {
            let data = response.data;

            for(let item in data)
                listObjects.push(data[item]);
        }

        this._callback(listObjects, error, status);
    }

    // Barcode

    postBarcode(idClient, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURLBarcode.replace("{idClient}", idClient),
            datas,
            (this._callback !== null ? this._callback : this.handlePostBarcode))
    }
    handlePostBarcode(response, error, status) {}

    putBarcode(idClient, id, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURLBarcode.replace("{idClient}", idClient) + "/" + id,
            datas,
            (this._callback !== null ? this._callback : this.handlePutBarcode))
    }
    handlePutBarcode(response, error, status) {}

    deleteBarcode(idClient, id) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURLBarcode.replace("{idClient}", idClient) + "/" + id,
            {},
            (this._callback !== null ? this._callback : this.handleDeleteBarcode))
    }
    handleDeleteBarcode(response, error, status) {}
}

export default ClientController;
