import Entity from "../../class/abstract/Entity";

class Tag extends Entity {
    name = null;
    color = null;
    icon = null;
    owner_type = null;
    owner_id = null;

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";

        for (let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                Reflect.set(this, key, datas[key]);
            }
        }
    }
}

export default Tag;
