import React, {useState} from "react";
import { ReactSVG } from "react-svg";
import $ from "jquery";
import TagIcon from "../../../icons/TagIcon";
import Color from "../../../../class/tool/Color";
import CrossIcon from "../../../icons/CrossIcon";
import FormStart from "../../tag/FormStart";
import Tag from "../../../../stories/_tag/Tag";

const InputSelectTag = props => {
    const { attribute, returnType, classname, value, type, list, refreshList, handleChange } = props;
    const [ showList, setShowList ] = useState(false);
    const [ showAdd, setShowAdd ] = useState(false);
    const colorAddButton = "#DADADA";

    const buildTag = (tag, index) => {
        let icon = (tag.icon !== null && tag.icon.length > 0) ? tag.icon : "tag.svg";
        let color = (tag.color !== null && tag.color.length > 0) ? tag.color : "#DADADA";
        let bgColor = color + "50";
        let iconColor = Color.shadeColor(color, -30);
        let textColor = Color.shadeColor(color, -70);

        return <div key={index} className={"tag" + (value.find(_ => _.id === tag.id) !== undefined ? " selected" : "")} style={{backgroundColor: bgColor}} onClick={() => { changeEvent(tag) }}>
            { buildIcon(iconColor, icon) }
            <p style={{color: textColor}}>{tag.name}</p>
        </div>;
    }
    const buildIcon = (color, icon) => {
        return <ReactSVG
            src={getResource(icon)}
            beforeInjection={(svg) => {
                svg.setAttribute('fill', color)
                $(svg).find("path").attr('fill', color)
            }}
            className={"icon"} />
    }
    const getResource = icon => {
        let imgSrc = icon;

        if (imgSrc.includes(".svg"))
            imgSrc = imgSrc.replace(".svg", "");

        try {
            return require("../../../../image/ressource/library/" + imgSrc + ".svg");
        }
        catch {
            return "";
        }
    }
    const handleShow = () => {
        setShowList(!showList);
    }
    const changeEvent = tag => {
        let tags = value.slice();
        let index = tags.findIndex(_ => _.id === tag.id)

        if (index >= 0) {
            tags.splice(index, 1);
        }
        else {
            tags.push(tag);
        }

        handleChange(attribute, returnType, tags)
    }
    const handleAdd = () => {
        setShowAdd(true);
    }
    const closeAdd = () => {
        setShowAdd(false);
    }

    return (
        <>
            <div className={"inputSelectTag" + (classname !== undefined ? " " + classname : "")} onClick={handleShow}>
                <TagIcon/>
                <p>Tags</p>
            </div>
            {
                showList
                && <div className={"overlayer"}>
                    <div className={"wrapOverbox"}>
                        <div className={"overbox start"}>
                            <p className={"title"}>Liste des tags</p>
                            <div className={"form"}>
                                <div className={"listTags"}>
                                    {list.map((tag, index) => buildTag(tag, index))}
                                    <div className={"tag"} style={{backgroundColor: colorAddButton + "80"}}>
                                        <div className={"icon"}>
                                            <CrossIcon fill={Color.shadeColor(colorAddButton, -100)}/>
                                        </div>
                                        <p style={{color: Color.shadeColor(colorAddButton, -130)}} onClick={handleAdd}>Ajouter</p>
                                    </div>
                                </div>
                                <button className="cancel noMarginRight noMarginTop" onClick={handleShow}>Fermer</button>
                                <div className="clearing"/>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                showAdd
                && <div className={"overlayer"}>
                    <div className={"wrapOverbox"}>
                        <div className={"overbox start"}>
                            <FormStart type={type} object={new Tag()} handleClose={closeAdd} handleIndex={refreshList} />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default InputSelectTag;