import React, {useContext, useEffect, useState} from "react"
import { useParams } from "react-router-dom"
import $ from "jquery"
import Menu from "./menu/Menu"
import Header from "./header/Header"
import Catalogs from "./content/catalog/Catalogs"
import Categories from "./content/category/Categories"
import SubCategories from "./content/subcategory/SubCategories"
import Products from "./content/product/Products"
import Screen from "./content/screen/Screen"
import SellerGroups from "./content/sellergroup/SellerGroups"
import Sellers from "./content/seller/Sellers"
import Pricelists from "./content/pricelist/Pricelists"
import PaymentMethods from "./content/paymentmethod/PaymentMethods"
import NotFound from "./content/404/NotFound"
import Sale from "./content/sale/Sale"
import Closing from "./content/closing/Closing"
import Printer from "./content/printer/Printer"
import Display from "./content/display/Display"
import BarcodeReader from "./content/barcodereader/BarcodeReader"
import PaymentTerminal from "./content/paymentterminal/PaymentTerminal";
import ObjectSheet from "../sheet/ObjectSheet"
import AccountList from "../overbox/account/AccountList"
import ErrorMessage from "./error/Error";
import ValidMessage from "./valid/Valid";
import Statistics from "./content/analyze/Statistics";
import CompanyController from "../../stories/_account/Companies/CompanyController";
import CatalogController from "../../stories/_catalog/Catalogs/CatalogController";
import PosScreenController from "../../stories/_setting/PosScreens/PosScreenController";
import SessionContext from "../../context/SessionContext"
import BackofficeContext from "../../context/BackofficeContext";
import '../../css/page/Content.css'
import Clients from "./content/client/Clients";

const BackOffice = props => {
    const { page } = props;
    const { handleCheckBigBisoux } = useContext(SessionContext);
    const [ boAccountsForm, setBoAccountsForm ] = useState(false);
    const [ informationForm, setInformationForm ] = useState(false);
    const [ settingForm, setSettingForm ] = useState(false);
    const [ accountForm, setAccountForm ] = useState(false);
    const [ bubbleText, setBubbleText ] = useState(null);
    const [ errorText, setErrorText ] = useState(null);
    const [ validText, setValidText ] = useState(null);
    const [ intervalToDeploy, setIntervalToDeploy ] = useState(null);
    const [ intervalCatalogDeploying, setIntervalCatalogDeploying ] = useState(null);
    const [ intervalScreenDeploying, setIntervalScreenDeploying ] = useState(null);
    const [ showToDeployButton, setShowToDeployButton ] = useState(null)
    const [ showDeployingCatalog, setShowDeployingCatalog ] = useState([])
    const [ showDeployingScreen, setShowDeployingScreen ] = useState([])
    const env = JSON.parse(localStorage.getItem("env"));
    const params = useParams();
    const intervalSecond = 15000;

    const goContent = () => {
        switch (page) {
            // statistic
            case "analyze": return <Statistics />
            case "sales": return <Sale page={ params.page !== undefined ? parseInt(params.page) : null } />
            case "closing": return <Closing page={ params.page !== undefined ? parseInt(params.page) : null } />

            // catalog
            case "catalogs": return <Catalogs page={ params.page !== undefined ? parseInt(params.page) : null } />
            case "pricelists": return <Pricelists page={ params.page !== undefined ? parseInt(params.page) : null } />
            case "categories": return <Categories page={ params.page !== undefined ? parseInt(params.page) : null } />
            case "subcategories": return <SubCategories page={ params.page !== undefined ? parseInt(params.page) : null } />
            case "products": return <Products page={ params.page !== undefined ? parseInt(params.page) : null } />

            // sellers
            case "sellergroups": return <SellerGroups page={ params.page !== undefined ? parseInt(params.page) : null } />
            case "sellers": return <Sellers page={ params.page !== undefined ? parseInt(params.page) : null } />

            // clients
            case "clients": return <Clients page={ params.page !== undefined ? parseInt(params.page) : null } />

            // settings
            case "screens": return <Screen page={ params.page !== undefined ? parseInt(params.page) : null } />
            case "paymentMethods": return <PaymentMethods page={ params.page !== undefined ? parseInt(params.page) : null } />

            // peripherals
            case "printers": return <Printer page={ params.page !== undefined ? parseInt(params.page) : null } />
            case "displays": return <Display page={ params.page !== undefined ? parseInt(params.page) : null } />
            case "barcodereaders": return <BarcodeReader page={ params.page !== undefined ? parseInt(params.page) : null } />
            case "paymentterminals": return <PaymentTerminal page={ params.page !== undefined ? parseInt(params.page) : null } />

            // 404
            case "404": return <NotFound />

            default: return <p>{ page + " introuvable" }</p>
        }
    }
    const closeBoAccountsForm = () => {
        setBoAccountsForm(false)
    }
    const closeInformationForm = () => {
        setInformationForm(false)
    }
    const closeSettingForm = () => {
        setSettingForm(false)
    }
    const closeAccountForm = () => {
        setAccountForm(false)
    }
    const bubbleHunter = () => {
        if (bubbleText === null) {
            window.removeEventListener('mousemove', moveBubble, false)
        }
        else {
            window.addEventListener('mousemove', moveBubble, false)
        }
    }
    const moveBubble = event => {
        let idBubbleItem = "#bubble"
        if ($(idBubbleItem).length === 0) return

        let posX = event.pageX
        let posY = event.pageY

        $(idBubbleItem).css({
            left: (posX + 10),
            top: (posY - 35),
        });
    }
    const getDatasToDeploy = (initInterval = false) => {
        if (env.type === "store") return

        const controller = new CompanyController()
        controller._callback = returnGetDatasToDeploy
        controller.tellMeWhatINeed()

        if (initInterval) {
            let interToDeploy = setInterval(() => { getDatasToDeploy() }, intervalSecond);
            setIntervalToDeploy(interToDeploy);
        }
    }
    const returnGetDatasToDeploy = (response, error, status) => {
        switch (status) {
            case 200:
                let datasToDeployed = null
                let catalogDatas = JSON.parse(response.data.catalogs)
                let screenDatas = JSON.parse(response.data.screens)

                if (Object.keys(catalogDatas).length > 0) {
                    for (let id in catalogDatas) {
                        if (catalogDatas[id].hasUpdates) {
                            datasToDeployed = 1
                            break
                        }
                    }
                }

                if (screenDatas.posscreens !== undefined && Object.keys(screenDatas.posscreens).length > 0) {
                    for (let id in screenDatas.posscreens) {
                        if (screenDatas.posscreens[id].hasUpdates) {
                            datasToDeployed = (datasToDeployed === null ? 2 : 3)
                            break
                        }
                    }
                }

                setShowToDeployButton(datasToDeployed)

                break
            default: break
        }
    }
    const getCatalogDeploymentInProgress = (initInterval = false) => {
        if (env.type === "store") return

        let controller = new CatalogController();
        controller._callback = returnGetCatalogDeploymentInProgress;
        controller.index("", 0, 0, true);

        if (initInterval) {
            let interDeploying = setInterval(() => { getCatalogDeploymentInProgress() }, intervalSecond);
            setIntervalCatalogDeploying(interDeploying);
        }
    }
    const returnGetCatalogDeploymentInProgress = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                localStorage.setItem("catalogs", JSON.stringify(list))

                if (list.length === 1)
                    localStorage.setItem("catalog", JSON.stringify(list[0]))
                else
                    localStorage.setItem("catalog", JSON.stringify({}))

                let indexFound;
                let deployingDatas = [];

                for (let index in list) {
                    if (list[index].planned_deployments_from.length > 0) {
                        indexFound = list[index].planned_deployments_from.findIndex(_ => _.jobbed === 0)

                        if (indexFound >= 0) {
                            deployingDatas.push(list[index]);
                        }
                    }
                    else if (list[index].planned_deployments_to.length > 0) {
                        indexFound = list[index].planned_deployments_to.findIndex(_ => _.jobbed === 0)

                        if (indexFound >= 0) {
                            deployingDatas.push(list[index]);
                        }
                    }
                }

                setShowDeployingCatalog(deployingDatas);

                break;
            default: break;
        }
    }
    const getScreenDeploymentInProgress = (initInterval = false) => {
        if (env.type === "store") return

        let controller = new PosScreenController();
        controller._callback = returnGetScreenDeploymentInProgress;
        controller.index();

        if (initInterval) {
            let interDeploying = setInterval(() => { getScreenDeploymentInProgress() }, intervalSecond);
            setIntervalScreenDeploying(interDeploying);
        }
    }
    const returnGetScreenDeploymentInProgress = (list, error, status) => {
        switch (status) {
            case 200:
                let indexFound;
                let deployingDatas = [];

                for (let index in list) {
                    if (list[index].planned_deployments_from.length > 0) {
                        indexFound = list[index].planned_deployments_from.findIndex(_ => _.jobbed === 0)

                        if (indexFound >= 0) {
                            deployingDatas.push(list[index]);
                        }
                    }
                    else if (list[index].planned_deployments_to.length > 0) {
                        indexFound = list[index].planned_deployments_to.findIndex(_ => _.jobbed === 0)

                        if (indexFound >= 0) {
                            deployingDatas.push(list[index]);
                        }
                    }
                }

                setShowDeployingScreen(deployingDatas);

                break;
            default: break;
        }
    }

    useEffect(() => {
        handleCheckBigBisoux();

        getDatasToDeploy(true);
        getCatalogDeploymentInProgress(true);
        getScreenDeploymentInProgress(true);
    }, []);
    useEffect(() => {
        return() => {
            clearInterval(intervalToDeploy)
            clearInterval(intervalCatalogDeploying)
            clearInterval(intervalScreenDeploying)
        }
    }, [intervalToDeploy, intervalCatalogDeploying, intervalScreenDeploying]);
    useEffect(() => {
        bubbleHunter();
    }, [bubbleText]);

    const valueBackofficeContext = {
        setBubbleText,
        setErrorText,
        setValidText,
        getDatasToDeploy,
        getCatalogDeploymentInProgress,
        getScreenDeploymentInProgress,
        showToDeployButton,
        showDeployingCatalog,
        showDeployingScreen
    }

    return (
        <BackofficeContext.Provider value={valueBackofficeContext}>
            <div className="backoffice interfaceStore">
                <div id={"bubble"} className={"bubbleHover" + (bubbleText !== null ? " visible" : "")}><p>{ bubbleText }</p></div>
                {
                    errorText !== null
                    && <ErrorMessage message={errorText} />
                }
                {
                    validText !== null
                    && <ValidMessage message={validText} />
                }
                <Menu page={page} />
                <Header setUserAccountForm={setAccountForm} setAccountsForm={setBoAccountsForm} setInformationForm={setInformationForm} setSettingForm={setSettingForm}/>
                {goContent()}
                {boAccountsForm && <AccountList handleClose={closeBoAccountsForm}/>}
                {informationForm && <ObjectSheet objectType="store" closeDisplay={closeInformationForm}/>}
                {settingForm && <ObjectSheet objectType="setting" closeDisplay={closeSettingForm}/>}
                {accountForm && <ObjectSheet objectType="account" closeDisplay={closeAccountForm}/>}
            </div>
        </BackofficeContext.Provider>
    )
}

export default BackOffice;
