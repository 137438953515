import Rest from "../../../class/tool/Rest"
import Seller from "./Seller"
import FormBuilder from "../../../class/tool/FormBuilder";

class SellerController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "sellers"
    _baseURLBarcode = "sellers/{idSeller}/barcodes"

    setFormValues(object, setValues, newObj = true) {
        let licenses = []

        if (object.licenses !== null) {
            for (let i = 0; i < object.licenses.length; i++)
                licenses.push(object.licenses[i].id)
        }

        if (newObj) {
            setValues(prev => ({
                ...prev,
                gender: object.gender !== null ? object.gender : "I",
                picture: object.picture,
                firstname: object.firstname,
                lastname: object.lastname,
                fullname: object.fullname,
                reference: object.reference,
                color: object.color,
                code: object.code,
                badge: object.badge,
                keyboardPosition: object.keyboardPosition,
                seller_group_id: object.seller_group_id,
                screen_id: object.screen_id,
                licenses: licenses
            }))
        }
        else {
            let barcodes = []

            if (object.barcodes !== null) {
                for (let key in object.barcodes) {
                    barcodes.push({
                        toAdd: false,
                        toUpdate: false,
                        toDelete: false,
                        id: object.barcodes[key].id,
                        barcode: object.barcodes[key].barcode,
                        conditioning: object.barcodes[key].conditioning,
                        created_at: object.barcodes[key].created_at,
                        updated_at: object.barcodes[key].updated_at,
                        deleted_at: object.barcodes[key].deleted_at
                    })
                }
            }

            setValues(prev => ({
                ...prev,
                gender: object.gender !== null ? object.gender : "I",
                picture: object.picture,
                firstname: object.firstname,
                lastname: object.lastname,
                fullname: object.fullname,
                reference: object.reference,
                color: object.color,
                code: object.code,
                badge: object.badge,
                keyboardPosition: object.keyboardPosition,
                seller_group_id: object.seller_group_id,
                screen_id: object.screen_id,
                licenses: licenses,
                barcode: barcodes
            }))
        }
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        let obj = Object.assign({}, object)
        let licenses = object.licenses
        obj.licenses = []

        for (let i in licenses) {
            obj.licenses.push(licenses[i].id)
        }

        return FormBuilder.returnUpdates(obj, datas, compare)
    }
    checkValues(object) {
        const params = [
            //{ name: "name", method: "checkName", val: object.name }
        ]
        let errors = {}
        let method

        for (let i in params) {
            method = SellerController[params[i].method]

            try { method.apply(null, params[i].val) }
            catch (e) { errors.params[i].name = e.message }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }
    checkMinimumManyValues(values) {
        if (Object.keys(values).length === 0) return false;
        if (values.lastname === null || values.lastname.length === 0) return false;
        if (values.firstname === null || values.firstname.length === 0) return false;
        if (values.seller_group_id === null || values.seller_group_id <= 0) return false;
        return true;
    }

    index(input = "", page = 1, nb = 25, sortingName = "", sortingValue = "", tree = false) {
        let params = ""
        if (page > 0) params += (params.length === 0 ? "?" : "&") + "page=" + page + "&nb=" + nb
        if (input.length > 0) params += (params.length === 0 ? "?" : "&") + "input=" + input
        if (tree) params += (params.length === 0 ? "?" : "&") + "tree="
        if (sortingName.length > 0) params += (params.length === 0 ? "?" : "&") + "sorton=" + sortingName + "&sortvalue=" + sortingValue

        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + params,
            {},
            this.handleIndex);
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = [];
        let pagination = null;

        if(status === 200) {
            let data;

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    }
                }

                data = response.data.data;
            }
            else if (response.data)
                data = response.data;

            for(let item in data)
                listObjects.push(new Seller(data[item]));
        }

        this._callback(listObjects, error, pagination, status);
    }

    maxPosition() {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + "/maxposition",
            {},
            this.handleMaxPosition)
    }
    handleMaxPosition = (response, error, status = 200) => {
        let listObjects = [];
        let pagination = null;

        if (status === 200) {
            let limit = parseInt(response.data);

            for (let i = 1; i <= limit; i++) {
                listObjects.push(new Seller({
                    "id": i,
                    "firstname": "Position",
                    "lastname": i.toString()
                }));
            }
        }

        this._callback(listObjects, error, pagination, status);
    }

    show(id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error, status = 200) => {
        const object = !error ? new Seller(response.data) : null;
        this._callback(object, error, status);
    }

    post(datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            datas,
            this.handlePost);
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status);
    }

    put(object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + object.id,
            datas,
            this.handlePut);
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status);
    }

    delete(object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status);
    }

    histo(idCatalog) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/histos',
            {},
            this.handleHisto);
    }
    handleHisto = (response, error, status = 200) => {
        let listObjects = [];

        if(status === 200) {
            let data = response.data;

            for(var item in data)
                listObjects.push(data[item]);
        }

        this._callback(listObjects, error, status);
    }

    histoTarget(idCatalog, id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + id + '/histos',
            {},
            this.handleHistoTarget);
    }
    handleHistoTarget = (response, error, status = 200) => {
        let listObjects = [];

        if(status === 200) {
            let data = response.data;

            for(let item in data)
                listObjects.push(data[item]);
        }

        this._callback(listObjects, error, status);
    }

    // Barcode

    postBarcode(idSeller, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURLBarcode.replace("{idSeller}", idSeller),
            datas,
            (this._callback !== null ? this._callback : this.handlePostBarcode))
    }
    handlePostBarcode(response, error, status) {}

    putBarcode(idSeller, id, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURLBarcode.replace("{idSeller}", idSeller) + "/" + id,
            datas,
            (this._callback !== null ? this._callback : this.handlePutBarcode))
    }
    handlePutBarcode(response, error, status) {}

    deleteBarcode(idSeller, id) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURLBarcode.replace("{idSeller}", idSeller) + "/" + id,
            {},
            (this._callback !== null ? this._callback : this.handleDeleteBarcode))
    }
    handleDeleteBarcode(response, error, status) {}
}

export default SellerController
