import React, { useEffect, useState } from "react"
import Switch from "react-switch"
import { ReactSVG } from "react-svg";
import $ from "jquery";
import Search2Icon from "../../../icons/Search2Icon"
import ToUppercase from "../../../icons/ToUppercase"
import ToBold from "../../../icons/ToBold"
import SeeIcon from "../../../icons/SeeIcon"
import StringTools from "../../../../class/tool/StringTools";
import Color from "../../../../class/tool/Color";
import PinTags from "../../../tools/PinTags";

const InputText = props => {
    const { attribute, returnType, color, type, maxChars, autoComplete, variablePrice, classname, backgroundColor, classError, value, bold, list, placeholder, tags, autoFocus, readonly, toUppercase, toBold, handleChange, handleBlur, handleSubmit, handleSearch, handleSelect } = props
    const [ inputType, setInputType ] = useState(type)
    const [ listDisplayed, setListDisplayed ] = useState(list)
    const [ readOnly, setReadOnly ] = useState((readonly !== undefined && readonly !== null) ? readonly : false)

    const buildTags = () => {
        if (tags.length < 3) {
            return <div className={"listTags inSearchInput"}>
                {
                    tags.map((tag, index) => buildTag(tag, index))
                }
            </div>
        }
        else {
            return <PinTags tags={tags} />
        }
    }
    const buildTag = (tag, index, withText = true) => {
        let icon = (tag.icon !== null && tag.icon.length > 0) ? tag.icon : "tag.svg";
        let color = (tag.color !== null && tag.color.length > 0) ? tag.color : "#DADADA";
        let bgColor = color + "50";
        let iconColor = Color.shadeColor(color, -30);
        let textColor = Color.shadeColor(color, -70);

        if (withText) {
            return <div key={index} className={"tag"} style={{backgroundColor: bgColor}}>
                { buildIcon(iconColor, icon) }
                <p style={{color: textColor}}>{tag.name}</p>
            </div>;
        }
        else {
            return <div className={"containerTag"}>
                <div key={index} className={"tag"} style={{backgroundColor: bgColor}}>
                    {buildIcon(iconColor, icon)}
                </div>
            </div>;
        }
    }
    const buildIcon = (color, icon) => {
        return <ReactSVG
            src={getResource(icon)}
            beforeInjection={(svg) => {
                svg.setAttribute('fill', color)
                $(svg).find("path").attr('fill', color)
            }}
            className={"icon"} />
    }
    const getResource = icon => {
        let imgSrc = icon;

        if (imgSrc.includes(".svg"))
            imgSrc = imgSrc.replace(".svg", "");

        try {
            return require("../../../../image/ressource/library/" + imgSrc + ".svg");
        }
        catch {
            return "";
        }
    }
    const filterList = () => {
        let listFiltred = []

        for(let index in list) {
            if (list[index].value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase())) {
                listFiltred.push(list[index])
            }
        }

        setListDisplayed(listFiltred)
    }
    const changeEvent = event => {
        let value = event.currentTarget.value

        if (maxChars !== undefined && value.length > maxChars) return
        if (handleChange === undefined || handleChange === null) return

        handleChange(attribute, returnType, value)
    }
    const blurEvent = () => {
        if (handleBlur === undefined || handleBlur === null) return
        handleBlur()
    }
    const keyDownEvent = event => {
        if (event.key === "Enter") {
            event.preventDefault()

            if (handleSubmit !== undefined && handleSubmit !== null)
                handleSubmit()
        }
    }
    const clickOnUppercase = () => {
        if (handleChange === undefined || handleChange === null) return

        let valueWithoutAccents = StringTools.removeAccents(value)
        let valueToTest = StringTools.removeSpecialChars(valueWithoutAccents)
        let regexMaj = /\b([A-Z]+)\b/g
        let result = valueToTest.match(regexMaj)
        let splitResult = value.split(' ')

        if (result !== null && ((splitResult.length > 0 && result.length === splitResult.length) || (splitResult.length === 0 && result.length === 1)))
            handleChange(attribute, returnType, value.toLocaleLowerCase())
        else
            handleChange(attribute, returnType, value.toLocaleUpperCase())
    }
    const clickOnBold = () => {
        toBold(attribute, returnType, false)
    }
    const clickOnSeePwd = () => {
        if (inputType === "password")
            setInputType("text")
        else
            setInputType("password")
    }
    const clickOnVariablePrice = () => {
        $(".variablePrice").focus()
        handleChange("variablePrice", "int", !variablePrice)
    }

    useEffect(() => {
        if (list !== undefined)
            document.querySelector('.smartList').style.width = document.querySelector('.inputText input').offsetWidth + "px"
    }, [])
    useEffect(() => {
        setListDisplayed(list)
    }, [list])
    useEffect(() => {
        filterList()
    }, [value])
    useEffect(() => {
        if (variablePrice === undefined || variablePrice === null) return

        if (variablePrice)
            setReadOnly(true)
        else
            setReadOnly(false)
    }, [variablePrice])

    return (
        <div className={ "inputText" + (classname !== undefined ? " " + classname : "") }>
            {
                (tags !== null && tags.length > 0)
                && buildTags()
            }
            <input
                type={ inputType !== undefined ? inputType : "text" }
                autoComplete={ autoComplete !== null ? autoComplete : "on" }
                className={
                    ((variablePrice !== undefined && variablePrice !== null) ? "variablePrice" : "extend") +
                    (bold === 1 ? " bold" : "") +
                    (classError !== undefined && classError.length > 0 ? " " + classError : "") +
                    ((tags !== null && tags.length > 0) ? " mid" : "")
                }
                value={ value !== null ? value : "" }
                placeholder={ placeholder }
                onChange={ changeEvent }
                onBlur={ blurEvent }
                autoFocus={ autoFocus !== null ? autoFocus : true }
                readOnly={ readOnly }
                onKeyDown={ keyDownEvent }
                style={{backgroundColor: ((backgroundColor !== undefined && backgroundColor !== null) ? backgroundColor + "22" : "")}} />
            {
                toUppercase !== null
                && <ToUppercase classname="toUppercase" handleClick={ clickOnUppercase } />
            }
            {
                toBold !== undefined
                && <ToBold classname="toBold" handleClick={ clickOnBold } />
            }
            {
                (variablePrice !== undefined && variablePrice !== null)
                && <div className="containerVariablePrice">
                    <p onClick={ clickOnVariablePrice }>Prix variable</p>
                    <Switch
                        checked={ variablePrice }
                        onChange={ clickOnVariablePrice }
                        onColor="#0065AC"
                        onHandleColor="#00406F"
                        handleDiameter={ 20 }
                        uncheckedIcon={ false }
                        checkedIcon={ false }
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={ 12 }
                        width={ 30 }
                        className="react-switch"
                        id="material-switch"
                    />
                </div>
            }
            {
                handleSearch !== undefined
                && <Search2Icon classname="searchInput" handleClick={ handleSearch } />
            }
            {
                type === "password"
                && <SeeIcon classname="searchInput" click={ clickOnSeePwd } />
            }
            {
                maxChars !== undefined
                && <p className="maxChars">{value.length}/{maxChars}</p>
            }
            <div className="clearing" />
            {
                list
                && <ul className="smartList" style={{width: document.querySelector('.inputText input')+"px"}}>
                    {
                        listDisplayed.map(item => (
                            <li key={ item.key } onClick={ () => { handleSelect(item.key) } }>
                                {
                                    item.value
                                }
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}

export default InputText;
