import Rest from "../../class/tool/Rest"
import FormBuilder from "../../class/tool/FormBuilder";
import Color from "../../class/tool/Color";
import Tag from "./Tag";

class TagController {
    #_rest = new Rest();
    _callback = null;
    _baseURL = "{type}/tags";

    setFormValues(object, setValues, newObj = true) {
        setValues(prev => ({
            ...prev,
            name: object.name,
            icon: newObj ? "tag_1" : object.icon,
            color: newObj ? Color.defaultColors[0] : object.color
        }))
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        let obj = Object.assign({}, object)

        return FormBuilder.returnUpdates(obj, datas, compare)
    }
    checkValues(object) {
        const params = []
        let errors = {}
        let method

        for (let i in params) {
            method = TagController[params[i].method]

            try { method.apply(null, params[i].val) }
            catch (e) { errors.params[i].name = e.message }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }

    index(type) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{type}", type),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = [];

        if(status === 200) {
            for(let item in response.data)
                listObjects.push(new Tag(response.data[item]));
        }

        this._callback(listObjects, error, status);
    }

    post(type, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{type}", type),
            datas,
            this.handlePost);
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status);
    }

    put(type, object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{type}", type) + '/' + object.id,
            datas,
            this.handlePut);
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status);
    }

    delete(type, object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{type}", type) + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status);
    }
}

export default TagController;