import Entity from "../../../class/abstract/Entity";
import Barcode from "../../_barcode/Barcode";
import StringTools from "../../../class/tool/StringTools";

class Client extends Entity {
    gender = null;
    firstname = null;
    lastname = null;
    fullname = null;
    reference = null;
    mobile = null;
    phone = null;
    email = null;
    birthday = null;
    description = null;
    alert = null;
    autoSendByEmail = null;
    allowMarketingCommunication= null;
    password = null;
    addresses = null;
    socialReason = null;
    intraTVA = null;
    siren = null;
    legalStatus = null;
    lastVisit = null;
    zerosixEnrollment = null;
    zerosixSended = null;
    url = null;
    urlSync = null;
    barcodes = null;
    owner_type = null;
    owner_id = null;
    last_histo_author = null;

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";
        let item;

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                if(key === "barcodes") {
                    this.barcodes = []

                    for (item in datas[key])
                        this.barcodes.push(new Barcode(datas[key][item]));
                }
                else
                    Reflect.set(this, key, datas[key]);
            }
        }

        let firstname = this.firstname !== null ? this.firstname : "";
        let lastname = this.lastname !== null ? this.lastname : "";
        Reflect.set(this, "fullname", (StringTools.capitalizeFirstLetter(firstname.toLocaleLowerCase()) + " " + lastname.toLocaleUpperCase()).trim());
    }
}

export default Client;
