import React, {useState} from "react";
import { ReactSVG } from "react-svg";
import $ from "jquery";
import Color from "../../class/tool/Color";
import "../../css/tools/PinTags.css";

const PinTags = props => {
    const { tags } = props;
    const [ showPreview, setShowPreview ] = useState(false);
    const idRandom = Math.floor(Math.random() * 999999);

    const buildTag = (tag, index) => {
        let icon = (tag.icon !== null && tag.icon.length > 0) ? tag.icon : "tag.svg";
        let color = (tag.color !== null && tag.color.length > 0) ? tag.color : "#DADADA";
        let bgColor = color + "50";
        let iconColor = Color.shadeColor(color, -30);

        return <div className={"containerTag"}>
            <div key={index} className={"tag"} style={{backgroundColor: bgColor}}>
                { buildIcon(iconColor, icon) }
            </div>
        </div>;
    }
    const buildCompleteTag = (tag, index) => {
        let icon = (tag.icon !== null && tag.icon.length > 0) ? tag.icon : "tag.svg";
        let color = (tag.color !== null && tag.color.length > 0) ? tag.color : "#DADADA";
        let bgColor = color + "50";
        let iconColor = Color.shadeColor(color, -30);
        let textColor = Color.shadeColor(color, -70);

        return <div key={index} className={"tag"} style={{backgroundColor: bgColor}}>
            { buildIcon(iconColor, icon) }
            <p style={{color: textColor}}>{tag.name}</p>
        </div>;
    }
    const buildIcon = (color, icon) => {
        return <ReactSVG
            src={getResource(icon)}
            beforeInjection={(svg) => {
                svg.setAttribute('fill', color)
                $(svg).find("path").attr('fill', color)
            }}
            className={"icon"} />
    }
    const getResource = icon => {
        let imgSrc = icon;

        if (imgSrc.includes(".svg"))
            imgSrc = imgSrc.replace(".svg", "");

        try {
            return require("../../image/ressource/library/" + imgSrc + ".svg");
        }
        catch {
            return "";
        }
    }
    const preview = value => {
        setShowPreview(value);

        if (value) {
            window.addEventListener('mousemove', movePreview, false)
        }
        else {
            window.removeEventListener('mousemove', movePreview, false)
        }
    }
    const movePreview = event => {
        let idPreviewList = "#previewListTags_" + idRandom;
        if ($(idPreviewList).length === 0) return;

        let posX = event.pageX;
        let posY = event.pageY;

        $(idPreviewList).css({
            left: (posX + 20),
            top: (posY - 40)
        });
    }

    return (<>
        <div id={"smallListTags_" + idRandom} className={"smallListTags"} onMouseOver={() => {preview(true)}} onMouseOut={() => {preview(false)}}>
            {tags.map((tag, index) => buildTag(tag, index))}
        </div>
        <div id={"previewListTags_" + idRandom} className={"previewListTags" + (showPreview ? " visible" : "")}>
            <div className={"list"}>
                {tags.map((tag, index) => buildCompleteTag(tag, index))}
            </div>
        </div>
    </>);
}

export default PinTags;
